var tlite = require('./tooltip.js');

function Tooltip(el, opts) {
    //if value is empty, null, or undefined don't make the toolip

    if (!opts.title && !opts.titleTemplateId) {
        return;
    }
    this.el = el;
    this.opts = opts;
    this.el.addEventListener('mouseover', this.show.bind(this), false);
    this.el.addEventListener('mouseleave', this.hide.bind(this), false);
}

Tooltip.prototype = {
    constructor: Tooltip,

    show: function() {
        var tliteOpts = {};
        if(this.opts.titleTemplateId && document.getElementById(this.opts.titleTemplateId)) {
            var template = document.createElement('span');
           template.innerHTML = '<span class="internal">' + document.getElementById(this.opts.titleTemplateId).innerHTML +'</span>';
            tliteOpts.text = template;
        } else {

            var template = document.createElement('span');
            template.innerHTML = '<span class="internal">' + this.opts.title || "" +'</span>';
            tliteOpts.text = template;
        }
        switch(this.opts.orientation) {
            case 'top': tliteOpts.grav = 's'; break;
            case 'left': tliteOpts.grav = 'e'; break;
            case 'right': tliteOpts.grav = 'w'; break;
            default: tliteOpts.grav = 'n'
        }
        tlite.show(this.el, tliteOpts);
    },
    hide: function() {
        tlite.hide(this.el);
    }
};



module.exports = Tooltip;
