"use strict";

function initComponents(types) {
  for (var i = 0; i < types.length; i++) {
    var el = types[i];
    switch (el.getAttribute("data-component")) {
    case 'tooltip':
      initTooltip(types[i]);
      break;
    case 'dialogbox':
      initDialogbox(types[i]);
      break;
    case 'typeahead':
      initTypeahead(types[i]);
      break;
    case 'gsmnummer':
      initNumberFormat(types[i]);
      break;
    }
  }
}

function initTooltip(el) {
  var title = el.getAttribute("data-title") || "";
  var titleTemplateId = el.getAttribute("data-title-template-id") || "";
  var orientation = el.getAttribute("data-orientation") || "";
  new huisstijl.Tooltip(el, {
    title: title,
    orientation: orientation,
    titleTemplateId: titleTemplateId
  });
}

function initDialogbox(el) { //this will prolly never be used with only data attributes
  var title = el.getAttribute('data-title') || '';
  var content = el.getAttribute('data-content') || '';
  var templateId = el.getAttribute('data-template-id') || '';
  var style = el.getAttribute('data-style') || 'default';

  var p = new huisstijl.Dialogbox(el, {
    title: title,
    content: content,
    templateId: templateId,
    style: style
  });

  el.addEventListener('click', function () {
    p.show();
  })
}

function initTypeahead(el) {
  var t = new huisstijl.Typeahead(el, {
    list: el.getAttribute('data-list') || [],
    minChars: el.getAttribute('data-minchars'),
    maxItems: el.getAttribute('data-maxitems'),
    reverse: el.getAttribute('data-reverse')
  })
}

function initNumberFormat(el) {
  var n = new huisstijl.Number(el);
}

var exports = module.exports = {};

// Add components to namespace
exports.Tooltip = require("./tooltip/tooltip.interface");
exports.Dialogbox = require("./dialogbox/dialogbox.interface");
exports.Datepicker = require("./datepicker/datepicker.interface");
exports.Typeahead = require("./typeahead/typeahead.interface");
exports.Number = require("./formatting/formatting.interface");
exports.Circlegraph = require("./circles/circles.interface");

exports.init = function () {
  var components = document.querySelectorAll('[data-component]');
  initComponents(components); 
};

/* other JS scripts that arent fullblown components*/
require("./toggleselect/toggleselect");
exports.validation = require("./formvalidation");
exports.scrollback = require("./scrollback");

