var pico = require('./dialogbox.js');


//Can be refactored to only take 1 param when versioning is started
function Dialogbox(el, opts) {
	this.opts = opts;
}

Dialogbox.prototype = {
	constructor: Dialogbox,

	show : function() {

		var options = this.opts;

		var picoOpts = {};

		//create full template
		var contentwrapper = document.createElement('div');

		//check for title
		if (options.title) {
			var titel = document.createElement('h3');
			titel.className = ' mt0';
			titel.textContent = options.title;
			contentwrapper.appendChild(titel);
		}

		//decide whether its flat text or template
		var contentbody = '';
		if(options.templateId && document.getElementById(options.templateId)) {
			var body = document.createElement('span');
			body.innerHTML = document.getElementById(options.templateId).textContent;
			contentbody = body;
		}
		else {
			var template = document.createElement('span');
			template.innerHTML = options.content || "";
			contentbody = template;
		}
		contentwrapper.appendChild(contentbody);

		//add confirmbutton
		var confirmbtn = document.createElement('button');
		confirmbtn.textContent = options.txtConfirm || "Bevestig";
		confirmbtn.className = ' button';
		confirmbtn.id = 'dialog-submit';
		confirmbtn.addEventListener('click', function() {
			if (options.onConfirm)
				options.onConfirm();

			db.close();
		});
		contentwrapper.appendChild(confirmbtn);

		//create cancelbutton
		var cancelbtn = document.createElement('button');
		cancelbtn.textContent = options.txtCancel || "Annuleer";
		cancelbtn.className = ' button ghost';
		cancelbtn.id = 'dialog-cancel';
		cancelbtn.addEventListener('click', function() {
			if(options.onCancel)
				options.onCancel();

			db.close();
		});
		
		//create footer + append buttons
		var footer = document.createElement('div');
		footer.className = 'form-footer pt0 pb0';
		footer.appendChild(confirmbtn);
        
        if (options.cancel === false) {
          //dont add cancelbutton
        }
         else {
           footer.appendChild(cancelbtn);
         }

		contentwrapper.appendChild(footer);

		//determine type of dialobox
		if (options.style === 'danger') {
			contentwrapper.className += ' dialogbox-danger';
			confirmbtn.className += ' danger';
		}
		else if (options.style === 'warning') {
			contentwrapper.className += ' dialogbox-warning';
			confirmbtn.className += ' warning';
		}
		else {
			contentwrapper.className += ' dialogbox-default';
		}

		//standard options
		picoOpts.content = contentwrapper;
		// picoOpts.width = '90%';
		picoOpts.overlayClose = false; //prevent closing by clicking outside
		picoOpts.closeButton = false;
		// picoOpts.overlayStyles = {backgroundColor: 'rgba(0,0,0,0.3)'};

		// style options
		// picoOpts.modalStyles = function(styles) {
		// 	styles.maxWidth = '31rem';
		// 	styles.boxShadow = '5px 5px 10px rgba(0,0,0,0.3)';
		// 	return styles;
		// };

		var db = pico(picoOpts)
			.show();
	}

};



module.exports = Dialogbox;